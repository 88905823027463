import {WebsiteOpener} from 'components';
import {MoreLess} from 'materials';
import React from 'react';
import LazyLoad from 'react-lazyload';
import {useHistory} from 'react-router-dom';

export const AgencyCard = props => {
	const {data, onClick} = props;
	const history = useHistory();
	return (
		<div onClick={() => history.push('/agency/' + data.id + '/clients')} className="agency-card">
			<LazyLoad>
				<div className="logo">
					{data.website_logo ? (
						<img
							alt=""
							src={
								data.website_logo.startsWith('http') ? data.website_logo : '//' + data.website_logo
							}
						/>
					) : (
						<img alt="" src={require('../../assets/images/no-logo.png')}/>
					)}
				</div>
			</LazyLoad>

			<p><MoreLess content={data.name} maxLimit="16"/></p>
			<WebsiteOpener url={data.url_list && data.url_list[0]} limit="20"/>

			<span className="item sz-12 mt-10">Region : {data.region || '-'}</span>

			{data.websites && (
				<span className="item sz-12 mt-10 horizon center">
          # of Clients :
          <button className="btn basic p-0 ml-4 text-underline" onClick={onClick}>
            {data.websites.length}
          </button>
        </span>
			)}
		</div>
	);
};