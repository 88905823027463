import {request} from 'helpers';

export class Services {
	static getCountries = async params => {
		const getRes = await request.get('/address/countries/', params, false);
		//console.log("getRes",{getRes})
		return getRes;
	};

	static getRegions = async params => {
		const getRes = await request.get('/address/regions/', params, false);
		//console.log("getRes",{getRes})
		return getRes;
	};

	static getCities = async params => {
		const getRes = await request.get('/address/cities/', params, false);
		//console.log("getRes",{getRes})
		return getRes;
	};

}
