import {InputElement} from 'materials';
import {inject, observer} from 'mobx-react';
import React, {useState} from 'react';
import {useForm} from 'react-hook-form'
import {AiOutlineLoading} from 'react-icons/ai';
import {useHistory} from 'react-router-dom';

export const SignIn = inject('AuthStore')(observer((props) => {
	const {register, handleSubmit, errors} = useForm();
	const history = useHistory();
	const [loading, setLoading] = useState(false);

	const onSubmit = async data => {
		setLoading(true);
		if (await props.AuthStore.signIn(data)) {
			history.push('/')
		} else setLoading(false);
	}

	return (
		<section className="login">
			<div className="card horizon center stretch no-border">
				<div className="login-image">
					<div className="logo vertical left middle">
					</div>
				</div>
				<div className="form">
					<div className="login-card vertical top">
						<h2>Sign in<span>to Connect</span></h2>

						<form className="per-100 mt-20" onSubmit={handleSubmit(onSubmit)} noValidate={true}>
							<label className="vertical left mt-20">
								<span>Email :</span>
								<InputElement
									name="email"
									className="per-100"
									type="text"
									errors={errors}
									messages={{
										required: "This field is required!",
										pattern: "Please, enter a correct email!"
									}}
									ref={register({
										required: true,
										pattern: /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,5}$/i
									})}/>
							</label>
							<label className="vertical left mt-20">
								<span>Password :</span>
								<InputElement
									name="password"
									className="per-100"
									type="password"
									errors={errors}
									messages={{
										required: "This field is required!"
									}}
									ref={register({
										required: true
									})}/>
							</label>
							{/*{fieldAlert('password')}*/}
							<button type="submit" className="btn primary   mt-20 horizon center">
								{loading && (
									<span className="btn-loader">
                      <AiOutlineLoading/>
                    </span>
								)}
								Login
							</button>
						</form>
						<p className="sz-12 mt-20 c-grey75 ">
							If you have problems? Please contact the
							<a href="mailto:admin@evet.com" className="ml-4">
								administrator.
							</a>
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}));

