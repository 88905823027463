import {AgencyCard, AppliedFilters, Filter, Loader, Pagination} from 'components';
import {SearchInput} from 'materials';
import {inject, observer} from 'mobx-react';
import qs from 'query-string';
import React, {useEffect} from 'react';
import {IoIosPeople} from 'react-icons/all';
import {useLocation} from 'react-router-dom';

export const Agencies = inject('AgencyStore')(
	observer(props => {
		const location = useLocation();

		useEffect(() => {
			const searchParams = qs.parse(location.search);
			props.AgencyStore.read({params: searchParams});
		}, [props.AgencyStore, location.search]);

		const {list, meta, state} = props.AgencyStore;

		return (
			<section className="vertical center">
				<div className="page-head network-single">
					<div className="container">
						<div className="page-title">
							<h3 className="horizon middle">
								<IoIosPeople className="mr-6"/>
								Agencies
							</h3>
							<SearchInput/>
						</div>
					</div>
				</div>
				{state === 'listing' ? (
					<Loader/>
				) : (
					<div className="container page-body">
						<div className="right">
							<Filter withOffices withRegions/>
						</div>
						<div className="left">
							<div className="card vertical top per-100">
								<div className="result-head">
									<div className="title">
										<div className="result-content">
											we found <span>{meta.count || 0}</span> results related to the agencies
										</div>
									</div>
								</div>

								<AppliedFilters/>
								<div className="per-100 grid col-200">
									{list.map(i => (
										<AgencyCard key={"network_list_" + i.id} data={i}/>
									))}
								</div>
								{/*<table className="list-table">*/}
								{/*  <thead>*/}
								{/*  <tr>*/}
								{/*    <td>Agency Name</td>*/}
								{/*    <td>Agency URL</td>*/}
								{/*    <td>Region</td>*/}
								{/*    <td># of Clients</td>*/}
								{/*  </tr>*/}
								{/*  </thead>*/}
								{/*  <tbody>*/}
								{/*  {list.map(i => (*/}
								{/*    <AgencyCard key={"network_list_"+i.id} data={i} />*/}
								{/*  ))}*/}
								{/*  </tbody>*/}
								{/*</table>*/}
							</div>
							<Pagination
								className="pagination mt-30 mb-40"
								page={(meta.offset / meta.limit) >> 0}
								count={meta.count}
								limit={meta.limit}
								range={9}
							/>
						</div>
					</div>
				)}
			</section>
		);
	})
);
