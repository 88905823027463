import {WebsiteOpener} from 'components';
import {MoreLess} from 'materials';
import React from 'react';
import LazyLoad from 'react-lazyload';
import {useHistory} from 'react-router-dom';

export const TinyId = props => {
	const history = useHistory();
	const push = () => props.to && history.push(props.to);

	return (
		<span onClick={push} className="tiny-id-card">
      {props.name !== '-' && (
				<LazyLoad>
					<div className="logo">
						{props.logo ? (
							<img alt="" src={props.logo.startsWith('http') ? props.logo : '//' + props.logo}/>
						) : (
							<img alt="" src={require('../../assets/images/no-logo.png')}/>
						)}
					</div>
				</LazyLoad>
			)}
			<div className="vertical middle left per-100">
        <p>
          <MoreLess content={props.name} maxLimit="30"/>
        </p>
				{props.url && <WebsiteOpener url={props.url} limit={20}/>}
      </div>
    </span>
	);
};
