import React from 'react';
import {Line, LineChart, ResponsiveContainer, Tooltip, XAxis} from 'recharts';

export const SummaryCard = props => {
	return (
		<div className="card summary-card per-32">
			<div className="content per-50">
				<span>{props.title}</span>
				<p>{props.count}</p>
			</div>
			<div className="chart-base per-50">
				<ResponsiveContainer height="100%" width="100%">
					<LineChart data={props.data}>
						<Line type="monotone" dataKey="Amount" stroke="#e94855" strokeWidth={2}/>
						<XAxis style={{visibility: 'hidden'}} dataKey="name"/>
						<Tooltip labelFormatter={label => label}/>
					</LineChart>
				</ResponsiveContainer>
			</div>
		</div>
	);
};
