import {inject, observer} from 'mobx-react';
import React from 'react';
import {RiLayout4Line} from 'react-icons/ri';
import {NewAdvertisers} from './NewAdvertisers/NewAdvertisers';
import {NewAgencies} from './NewAgencies/NewAgencies';
import {Overview} from './Overview';
import {Summary} from './Summary/Summary';
import {TrendingAgencies} from './TrendingAgencies/TrendingAgencies';

export const Dashboard = inject(
	'UIStore',
	'SummaryStore'
)(
	observer(() => {
		return (
			<section className="vertical center">
				<div className="page-head">
					<div className="container">
						<div className="page-title">
							<h3 className="horizon middle">
								<RiLayout4Line className="mr-6"/>
								Dashboard
							</h3>
						</div>
					</div>
				</div>
				<div className="container page-body">
					<div className="left">
						<Summary/>
						<NewAdvertisers/>
						<NewAgencies/>
						<TrendingAgencies/>
					</div>
					<div className="right">
						<Overview/>
					</div>
				</div>
			</section>
		);
	})
);
