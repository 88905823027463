import {inject, observer} from "mobx-react";
import React from 'react';
import {FaExternalLinkAlt, FaNetworkWired, FiMenu, IoIosPeople} from 'react-icons/all';
import {Link} from "react-router-dom";
import packageJson from '../../../package.json';

export const Menu = inject('UIStore')(observer((props) => {

	const isOpen = props.UIStore.dropdown.Menu;

	const toggleMenu = (e) => {
		if (e)
			e.stopPropagation();
		props.UIStore.toggleDropDown('Menu');
		window.addEventListener('click', () => {
			props.UIStore.toggleDropDown('Menu', false);
		});
	};

	return (
		<div className={"menu horizon between stretch pl-10 pr-10 " +
		(isOpen ? 'opened-menu' : '')
		}>
			<div className="menu-button horizon between middle" onClick={toggleMenu}>
				<FiMenu className="f-primary "/>
				<p className="sz-14 ml-10 f-primary">Menu</p>
			</div>
			<div className={"menu-dropdown horizon between stretch shadow-box " + (isOpen ? 'show' : 'hide')}>
				<div className="links vertical left middle">
					<Link to="/advertisers" onClick={toggleMenu}>
						<FaExternalLinkAlt/>
						<span>Advertisers </span>
					</Link>
					<Link to="/networks" onClick={toggleMenu}>
						<FaNetworkWired/>
						<span>Networks </span>
					</Link>
					<Link to="/agencies" onClick={toggleMenu}>
						<IoIosPeople/>
						<span>Agencies </span>
					</Link>
					<span className="version">v{packageJson.version}</span>
				</div>
			</div>
		</div>
	);
}));
