import {Sectors, TinyId} from 'components';
import React from 'react';

const Item = props => (
	<tr>
		<td>
			<TinyId
				logo={props.merchant.website_logo}
				name={props.merchant.name}
				url={props.website.url}
			/>
		</td>
		<td>
			<TinyId
				logo={props.network.website_logo}
				name={props.network.name || '-'}
				to={props.network.id ? '/network/' + props.network.id + '/clients' : ''}
			/>
		</td>
		{/* <td>-</td> */}
		<td><Sectors sectors={props.merchant.sectors}/></td>
	</tr>
);

export const Advertiser = props => {
	const {data} = props;

	return (
		<React.Fragment>
			{data.websites.length ? (
				data.websites.map(i => <Item merchant={data} website={i} network={i.networks[0] || {}}/>)
			) : (
				<Item merchant={data} website={{}} network={{}}/>
			)}
		</React.Fragment>
	);
};
