import {AgencyCard, Loader} from 'components';
import {inject, observer} from 'mobx-react';
import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

export const NewAgencies = inject('NewAgencyStore')(
	observer(props => {
		useEffect(() => {
			props.NewAgencyStore.read({});
		}, [props.NewAgencyStore]);

		const {list, state} = props.NewAgencyStore;

		return (
			<div className="card per-100 mt-10">
				<div className="card-title">
					<h3>New Agencies</h3>
				</div>
				{state !== 'done' ? (
					<Loader padding={true}/>
				) : (
					<div className="card-body grid col-180 ">
						{/* TABLE DESIGN  <div className="card-body per-100">
             <table className="list-table per-100">
              <thead>
              <tr>
                <td>Agency Name</td>
                <td>Agency URL</td>
                <td>Region</td>
                <td># of Clients</td>
              </tr>
              </thead>
              <tbody> */}

						{list.map(i => (
							<AgencyCard
								key={'new_agencies_' + i.id}
								data={i}
							/>
						))}
						{/* </tbody>
            </table> */}

					</div>
				)}

				<div className="card-action p-10 horizon center">
					<Link className="sz-12" to="/agencies">
						Show All Agencies
					</Link>
				</div>
			</div>
		);
	})
);
