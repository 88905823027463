import React, {Component} from "react";

export class TimeTogglePicker extends Component {
	render() {
		return (
			<div className="switch-time-range">
				<label className="item">
					<input type='checkbox' checked/>
					<div className="button">
						<p>To Day</p>
					</div>
				</label>
				<label className="item">
					<input type='checkbox'/>
					<div className="button">
						<p>This Week</p>
					</div>
				</label>
				<label className="item">
					<input type='checkbox'/>
					<div className="button">
						<p>This Month</p>
					</div>
				</label>
			</div>
		);
	}
}
