import React from 'react';

export const Overview = () => {
	return (
		<div className="card p-20 overview-card">
			<h2 className="sz-16 fw-500">
				Overview
				<br/>
				Evet Connect
			</h2>
			<p className="sz-12 f-grey mt-20">
				Evet Connect is a free platform for finding business information about affiliate advertisers
				worldwide. It is a comprehensive platform for affil iate marketers to identify and track new
				affiliate advertisers.
				<br/>
				<br/>
				You can also research and get insights about your competitors by conducting market research
				and discover new leads. Access real-time and actionable data by using Evet Connect's tools.
			</p>
			<div className="horizon right per-100 mt-20">
				<img alt="" className="per-50" src={require('../../assets/images/connect-symbol.png')}/>
			</div>
		</div>
	);
};
