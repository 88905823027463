import {AddressFormatter} from 'components';
import {inject, observer} from 'mobx-react';
import React from 'react';

export const Overview = inject('UIStore')(
	observer(props => {
		const {item, clientCount} = props;

		// const renderNetworks = () =>
		//   Object.values(item.networks || {}).map(i => <span key={'network_' + i.id}>{i.name}</span>);

		return (
			<div className="card p-0 overview-card">
				<h2 className="sz-16 fw-500 p-20 pt-10 pb-10">Overview</h2>
				{(item.offices || []).length ? (
					<div className="section">
						<p>Offices</p>
						{item.offices.map(i => (
							<AddressFormatter
								key={'office_' + i.id}
								city={i.city}
								region={i.region}
								country={i.country}
							/>
						))}
					</div>
				) : (
					<React.Fragment/>
				)}

				<div className="section">
					<p>Sectors</p>
					<span>Agency cannot have sectors</span>
				</div>

				{/* <div className="section">
          <p>Networks</p>
          {renderNetworks()}
        </div> */}

				<div className="section">
					<p># of Total Clients</p>
					<div className="count">
						<span>{clientCount}</span>
					</div>
				</div>
			</div>
		);
	})
);
