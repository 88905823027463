import {AddButton, SearchInput} from "materials";
import {inject, observer} from "mobx-react";
import React, {useState} from "react";
import {MdArrowDropDown, MdArrowDropUp} from 'react-icons/md';
import {useHistory} from "react-router-dom";
import {UrlItem} from "./URLItem";

export const URLSwitch = inject('AccountStore', 'UIStore')(observer((props) => {

	const history = useHistory();
	const isOpen = props.UIStore.dropdown.URLSwitch;
	const [filter, setFilter] = useState('');

	const toggleMenu = (e) => {
		if (e)
			e.stopPropagation();
		props.UIStore.toggleDropDown('URLSwitch');
		window.addEventListener('click', () => {
			props.UIStore.toggleDropDown('URLSwitch', false);
		});
	};

	const stopAction = (e) => {
		e.stopPropagation();
	};

	const changeUrl = (e) => {
		e.preventDefault();
		const id = e.currentTarget.dataset.id;
		const me = props.AccountStore.profile;
		me.changeUrl(id);
		toggleMenu();
		history.push('/');
	};

	const handleFilter = (e) => {
		e.preventDefault();
		setFilter(e.currentTarget.value);
	};

	const me = props.AccountStore.profile;
	const url = me.url;
	const urls = (me.urls || []);
	const filteredURLs = urls.filter(i => i.url && i.url.toLowerCase().indexOf(filter.toLowerCase()) !== -1);

	return (
		<div id="urlSelector" className={"switch-url  vertical middle left " +
		(isOpen ? "opened-menu" : '')}>
			{urls.length ?
				<React.Fragment>
					<div className="selected-url vertical middle" onClick={toggleMenu}>
						<MdArrowDropDown className={"f-white " + (isOpen ? 'hide' : 'show')}/>
						<MdArrowDropUp className={"f-white " + (isOpen ? 'show' : 'hide')}/>
						<div className="item pl-10">
							<p className="sz-14 ">
								{url.url.slice(0, 20)}{' '}{url.country_cd}
							</p>
							<span className="sz-12">ID {url.url_id}</span>
						</div>
					</div>
					<div className={"url-list shadow-box" + (isOpen ? ' show' : '')}>
						{urls.length > 0 &&
						<div className="search per-100 vertical middle">
							<label className="per-100">
								<SearchInput
									onChange={handleFilter} value={filter} onClick={(e) => e.stopPropagation()}/>
							</label>
						</div>
						}
						<div id='scrollBar' className="scroll-base per-100">
							{filteredURLs.map((i, k) =>
								<UrlItem
									key={i.url_id + '-' + k}
									url={i.url}
									country={i.country_cd}
									data-id={i.url_id}
									status={i.url_status}
									onClick={
										i.url_status === 'ACTIVE' ? changeUrl : stopAction
									}
								/>
							)}
						</div>
						<div className="action p-10" onClick={toggleMenu}>
							<AddButton name="Add New Url" to="/websites/add"/>
						</div>
					</div>
				</React.Fragment>
				: <div className="action p-10" onClick={toggleMenu}>
					<AddButton
						className="horizon center"
						name="Add New Url"
						to="/websites/add"/>
				</div>}
		</div>);

}));
