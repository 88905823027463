import {HistoryCard} from 'materials';
import {inject, observer} from 'mobx-react';
import React from 'react';

export const History = inject('UIStore')(
	observer(props => {
		return (
			<div className="card mt-10">
				<div className="card-title">
					<h3>Agencies</h3>
				</div>
				<div className="card-body ivy left">
					<HistoryCard
						title="New Advertiser"
						result="3"
						className="positive"
						clients="Dell.com, Nike.com, Adidas.com"
						agency="Opm Pros"
						event="Event description text"
					/>
					<HistoryCard
						title="Lost Advertiser"
						result="2"
						className="negative"
						clients="Dell.com, Nike.com"
						agency="Opm Pros"
						event="Event description text"
					/>
				</div>
			</div>
		);
	})
);
