import {inject, observer} from "mobx-react";
import React from "react";
import {MdPowerSettingsNew} from "react-icons/all";
import {Link, useHistory} from "react-router-dom";
import {Menu} from "../";


export const Header = inject()(observer((props) => {
	const history = useHistory();
	const handleHome = () => {
		history.push('/');
	};

	// const toggleMenu = (e) => {
	//   if (e)
	//     e.stopPropagation();
	//   props.UIStore.toggleDropDown('Menu');
	//   window.addEventListener('click', () => {
	//     props.UIStore.toggleDropDown('Menu', false);
	//   });
	// };

	return (
		<header className="horizon stretch between per-100">
			<div className="project horizon between ">
				<div className="brand horizon middle left">
					<button onClick={handleHome} style={{border: 0, background: "transparent", padding: 0, cursor: "pointer"}}>
						<img alt="evet" src={
							require('../../assets/images/logo.png')
						}/>
					</button>
					<p className="sz-14 fw-500 pl-20 f-primary">
						Evet Connect
					</p>
				</div>
				<Menu/>
			</div>
			<div className="navigation horizon stretch right">
				<Link to="/logout">
					<MdPowerSettingsNew/>
					<span>Logout</span>
				</Link>
			</div>
		</header>
	);
}));
