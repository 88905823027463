import {action, observable} from 'mobx';

export default class {
	@observable height = window.innerHeight;
	@observable width = window.innerWidth;
	@observable alerts = new observable.map();
	@observable modal = {};
	@observable side = {};
	@observable dropdown = {
		Menu: false,
		UserMenu: false,
		URLSwitch: false,
		ReportsMenu: false,
		NotificationMenu: false
	};

	constructor(Stores) {
		this.stores = Stores;
	}

	@action setHeight = height => (this.height = height);

	@action setWidth = width => (this.width = width);

	@action
	updateWindowSize = (width, height) => {
		this.width = width;
		this.height = height;
	};

	@action
	toggleDropDown = (menu, status = true) => {
		if (status)
			Object.keys(this.dropdown).forEach(i => i !== menu ? this.dropdown[i] = false : null)
		this.dropdown[menu] = status && !this.dropdown[menu];
	};


	@action
	modalOpen = ({id, title, content, actions, show, type, onClose}) => {
		show = show !== false;
		type = type || 'normal';
		this.modal = {id, title, content, actions, show, type, onClose};
	};

	@action
	modalClose = () => {
		if (this.modal.onClose)
			this.modal.onClose();
		this.modal = {id: '', title: '', content: '', action: '', show: false, type: '', onClose: null};
	};

}
