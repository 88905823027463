export class Meta {

	constructor(data) {
		if (data)
			Object.entries(data).forEach(([k, i]) => this[k] = i)
	}
}

export class Network {
	agenciesCount = 0;

	constructor(data) {
		if (data)
			Object.entries(data).forEach(([k, i]) => this[k] = i)
	}

	get agencies() {
		if (!this.websites) return {};
		const agencies = {}
		this.websites.forEach(website => {
			if (website.agency) agencies[website.agency.id] = website.agency;
		})

		this.agenciesCount = Object.keys(agencies).length
		return agencies;
	}

}