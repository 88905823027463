import AccountStore from './AccountStore';
import AddressStore from './AddressStore';
import AgencyStore from './AgencyStore';
import AuthStore from './AuthStore';

import ContactStore from './ContactStore';
import DetailAgencyStore from './DetailAgencyStore';

import DetailClientStore from './DetailClientStore';
import MerchantStore from './MerchantStore';
import NetworkStore from './NetworkStore';
import NewAgencyStore from './NewAgencyStore';
import NewMerchantStore from './NewMerchantStore';
import SummaryStore from './SummaryStore';
import SystemMessageStore from './SystemMessageStore';
import TrendingAgencyStore from './TrendingAgencyStore';
import UIStore from './UIStore';


class RootStore {
	constructor() {
		this.UIStore = new UIStore(this);
		this.SystemMessageStore = new SystemMessageStore(this);
		this.AuthStore = new AuthStore(this);
		this.AccountStore = new AccountStore(this);

		this.ContactStore = new ContactStore(this);
		this.NetworkStore = new NetworkStore(this);
		this.MerchantStore = new MerchantStore(this);
		this.AgencyStore = new AgencyStore(this);
		this.AddressStore = new AddressStore(this);
		this.SummaryStore = new SummaryStore(this);
		this.NewMerchantStore = new NewMerchantStore(this);
		this.NewAgencyStore = new NewAgencyStore(this);
		this.TrendingAgencyStore = new TrendingAgencyStore(this);

		this.DetailClientStore = new DetailClientStore(this);
		this.DetailAgencyStore = new DetailAgencyStore(this);

	}
}

export const stores = new RootStore();
