import {WebsiteOpener} from 'components';
import React from 'react';
import LazyLoad from 'react-lazyload';
import {Link} from 'react-router-dom';
import {Sectors} from '../Sectors';

export const ClientCard = props => {
	const {metaData, network} = props;

	return (
		<div className="client-card">
			<div className="title">
				<LazyLoad>
					<div className="logo">
						{metaData.website_logo ? (
							<img
								alt=""
								src={
									metaData.website_logo.startsWith('http')
										? metaData.website_logo
										: '//' + metaData.website_logo
								}
							/>
						) : (
							<img alt="" src={require('../../assets/images/no-logo.png')}/>
						)}
					</div>
				</LazyLoad>

				<div className="content">
					<p>{metaData.merchant.name}</p>
					<WebsiteOpener url={metaData.url} limit="20"/>
				</div>
			</div>

			<div className="item">
				<span>Network</span>
				<span>
          :
          <Link to={'/network/' + network.id + '/clients'}>{network.name}</Link>
        </span>
			</div>

			<div className="item">
				<span>Markets</span>
				<span>: -</span>
			</div>
			<div className="item">
				<span>Sector</span>
				<span>
          : <Sectors sectors={metaData.merchant.sectors}/>
        </span>
			</div>
		</div>
	);
};
