import {computed} from 'mobx';
import moment from 'moment';

export class Summary {
	constructor(data) {
		if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i));
	}

	@computed
	get data() {
		const res = Object.entries(this['historic']).map(([date, Amount]) => {
			const returning = {name: date, date, Amount};
			return returning;
		});
		res.sort((a, b) => moment(a.date) - moment(b.date));
		return res;
	}

	get count() {
		return this['total'];
	}

	// get id() {
	//   return this['user_id']
	// }
}
