import React, {useEffect, useState} from "react";
import {MdCheck} from 'react-icons/md';
import {QuickTips} from "./QuickTips";

export const CheckBox = React.forwardRef((props, ref) => {

	const isChecked = props.checked || (props.hasOwnProperty('checkControl') ? props.checkControl(props.name, props.value) : false)

	const [checked, setChecked] = useState(isChecked);

	useEffect(() => {
		setChecked(isChecked)
	}, [isChecked])

	return (
		<label style={props.style}>
			<div className="check-box">
				<input name={props.name} type="checkbox"
							 value={props.value || true} defaultChecked={checked}
							 onChange={(e) => props.onChange ? props.onChange(e) : setChecked(!checked)} ref={ref}/>
				<MdCheck/>
				<span>{props.label}</span>
			</div>
			{props.tip && <QuickTips comment={props.tip}/>}
		</label>
	);
});
