import base64 from 'base-64';
import cookie from 'react-cookies';


const API_URL = process.env.REACT_APP_API_URL;
const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
const prefix = 'connect';

export class request {
	static get token() {
		const token = cookie.load(prefix + '_id_token');
		return token || this.refreshToken().then();
	}

	static get email() {
		return cookie.load(prefix + '_email');
	}

	static get access_token() {
		return cookie.load(prefix + '_access_token');
	}

	static refreshToken = async () => {
		const cookies = cookie.loadAll();
		const client_id = cookies[prefix + '_client_id'];
		const client_secret = cookies[prefix + '_client_secret'];
		if (!!client_id && !!client_secret) {

			try {
				const headers = new Headers();
				headers.set('Content-Type', 'application/x-www-form-urlencoded');
				headers.set(
					'Authorization',
					'Basic ' + base64.encode(client_id + ':' + client_secret)
				);

				const body = `grant_type=client_credentials`;
				const token = await request.post('/o/token/', body, false, headers, true).then();
				//console.log(token);
				request.setCookies({
					access_token: token.access_token,
					expires_in: token.expires_in
				});

				return true; // return true;
			} catch (e) {
				console.log(e);
				throw e;
			}


		} else {
			this.deleteCookies();
			if (window.location.pathname !== LOGIN_URL)
				window.location.href = LOGIN_URL;
		}
	};

	static deleteCookies = () => {
		localStorage.removeItem('me');
		localStorage.clear();
		cookie.save(prefix + '_access_token', '', {path: '/'});
		cookie.save(prefix + '_id_token', '', {path: '/'});
		cookie.save(prefix + '_refresh_token', '', {path: '/'});
		cookie.save(prefix + '_remember', '', {path: '/'});
		cookie.save(prefix + '_client_id', '', {path: '/'});
		cookie.save(prefix + '_client_secret', '', {path: '/'});

		cookie.remove(prefix + '_access_token');
		cookie.remove(prefix + '_refresh_token');
		cookie.remove(prefix + '_id_token');
		cookie.remove(prefix + '_remember');
		cookie.remove(prefix + '_client_id');
		cookie.remove(prefix + '_client_secret');
	};

	static setCookies = ({client_id, client_secret, access_token, expires_in, remember, email}) => {

		const expires = new Date();
		if (remember) {
			expires.setTime(Date.now() + 1000 * 60 * 60 * 24 * 14); //2 weeks
		} else {
			expires.setTime(Date.now() + 1000 * 60 * 10); //10 mins
		}
		if (access_token) {
			cookie.save(prefix + '_id_token', access_token, {
				path: '/',
				maxAge: expires_in * 2 / 3,
			});
		}
		if (client_id)
			cookie.save(prefix + '_client_id', client_id, {
				path: '/',
				expires,
			});
		if (client_secret)
			cookie.save(prefix + '_client_secret', client_secret, {
				path: '/',
				expires,
			});
		if (remember)
			cookie.save(prefix + '_remember', remember, {
				path: '/',
				expires,
			});
		if (email)
			cookie.save(prefix + '_email', email, {
				path: '/',
				expires,
			});
	};

	static get = async (endpoint, params = {}, secured = true) => {
		const token = secured ? await this.token : null;
		if (endpoint === '/ppc-details' || endpoint === '/ppc-details/advertisers') {
			params = params
				? Object.keys(params)
					.map(k =>
						String(params[k]).includes(',')
							? String(params[k])
								.split(',')
								.map(m => (m && m !== '' ? encodeURI(k) + '=' + encodeURI(m) : ''))
								.join('&')
							: encodeURI(k) + '=' + encodeURI(params[k])
					)
					.join('&')
				: '';
		} else {
			params = params
				? Object.keys(params)
					.map(k => encodeURI(k) + '=' + encodeURI(params[k]))
					.join('&')
				: '';
		}
		const url = API_URL + endpoint + (params ? '?' + params : '');
		const response = await fetch(url, {
			method: 'GET',
			headers: {'Content-Type': 'application/json', Authorization: "Bearer " + token},
			mode: 'cors',
		});
		const result = await response.json();
		if (response.status === 200) return result || true;
		if (response.status === 401) throw new Error(result['detail'] + ' Please refresh page or logout')

		throw new Error(result['error']);
	};

	static post = async (endpoint, data, secured = true, headers) => {
		const token = secured ? await this.token : null;
		const url = API_URL + endpoint;
		const body = {
			method: 'POST',
			headers: headers || {'Content-Type': 'application/json', Authorization: "Bearer " + token},
			mode: 'cors',
			body: typeof data === 'string' ? data : JSON.stringify(data),
		};

		const response = await fetch(url, body);
		const result = await response.json();
		if (response.status === 200) return result || true;
		if (response.status === 201) return result || true;
		if (response.status === 403) throw new Error(result['detail']);
		// TODO 400 response can bu handled with somehow different
		if (response.status === 400) {

			for (const [index, element] of Object.entries(result)) {
				if (index === 'contacts') {
					const keys = Object.keys(element)
					const values = Object.values(element)

					values.forEach(((e, i) => {
						//console.log({e,i,k:keys[i]})
						throw new Error(`${keys[i]} : ${e[0]}`);
					}))


				} else if (index === 'websites') {
					for (const [index2, element2] of Object.entries(result.websites)) {
						if (index2 === 'contacts') {
							const keys2 = Object.keys(element2)
							const values2 = Object.values(element2)

							values2.forEach(((e2, i2) => {
								//console.log({e,i,k:keys[i]})
								throw new Error(`${keys2[i2]} : ${e2[0]}`);
							}))


						} else {
							throw new Error(`${index2} : ${element2}`);
						}
					}
				} else {
					throw new Error(`${index} : ${element}`);
				}
			}


		}


		throw new Error(result['error']);
	};

	static put = async (endpoint, data, secured = true) => {
		const token = secured ? await this.token : null;
		const url = API_URL + endpoint;
		const response = await fetch(url, {
			method: 'PUT',
			headers: {'Content-Type': 'application/json', Authorization: "Bearer " + token},
			mode: 'cors',
			body: JSON.stringify(data),
		});
		const result = await response.json();
		if (response.status === 200) return result || true;

		throw new Error(result['error_message']);
	};

	static delete = async (endpoint, data, secured = true) => {
		const token = secured ? await this.token : null;
		const url = API_URL + endpoint;
		const response = await fetch(url, {
			method: 'DELETE',
			headers: {'Content-Type': 'application/json', Authorization: "Bearer " + token},
			mode: 'cors',
			body: JSON.stringify(data),
		});
		const result = await response.json();
		//console.log({result});
		if (response.status === 200) return result || true;

		throw new Error(result['error_message']);
	};
}
