import {ClientCard, Loader, Pagination} from 'components';
import {SearchInput} from "materials";
import {inject, observer} from 'mobx-react';
import React from 'react';

export const Clients = inject('UIStore')(
	observer(props => {
		const {clients, state, meta} = props;

		return (
			<React.Fragment>
				<div className="card mt-10">
					{state === 'listing' ? (
						<Loader padding/>
					) : (
						<React.Fragment>
							<div className="card-title">
								<h3>Clients</h3>
								<SearchInput
									className="card-search"
								/>
							</div>

							{/* TABLE DESIGN <table className="list-table per-100">
              <thead>
              <tr>
                <td>Client</td>
                <td>Network</td>
                <td>Market</td>
                <td>Sectors</td>
                <td>-</td>
              </tr>
              </thead>
              <tbody> */}
							<div className="card-body ivy left">
								{(clients || []).map(client =>
									client.networks.length ? (
										client.networks.map(network => (
											<ClientCard
												key={`new_advertiser_${client.id}_${network.id}`}
												metaData={client}
												network={network}
											/>
										))
									) : (
										<ClientCard key={`new_advertiser_${client.id}`} metaData={client} network={{}}/>
									)
								)}
							</div>
							{/* TABLE DESIGN </tbody>
            </table> */}

						</React.Fragment>
					)}
				</div>
				{meta.count ? <Pagination
					className="pagination mt-30 mb-40"
					page={(meta.offset / meta.limit) >> 0}
					count={meta.count}
					limit={meta.limit}
					range={9}
				/> : null}
			</React.Fragment>
		);
	})
);
