import React from 'react';

export const Loader = (props) => {
	return props.padding
		? <div className="mb-100 mt-100">
			<div className={'loader ' + (props.background ? 'background' : '')}>
				<div id="round1">
					<span/>
					<span/>
				</div>
			</div>
		</div>
		: <div className={'loader ' + (props.background ? 'background' : '')}>
			<div id="round1">
				<span/>
				<span/>
			</div>
		</div>;
};
