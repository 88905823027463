import {Loader} from 'components';
import {LayoutDefault, LayoutGuest, LayoutNoHeader} from 'layouts';
import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import {Advertisers, Agencies, Agency, Dashboard, Login, Logout, Network, Networks, NoMatch,} from './pages';

const RestrictedRoute = ({
  component: Component,
  layout: Layout = LayoutDefault,
  authenticated,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/logout',
              state: {from: props.location},
            }}
          />
        )
      }
    />
  );
};

const GuestRoute = ({component: Component, layout: Layout = LayoutGuest, ...rest}) => {
  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

@inject('UIStore', 'AuthStore', 'AccountStore')
@observer
class Routes extends Component {
  componentDidMount() {
    const {authenticated, handleAuth} = this.props.AuthStore;

    window.addEventListener('resize', () => {
      this.props.UIStore.updateWindowSize(window.innerWidth, window.innerHeight);
    });
    this.props.UIStore.updateWindowSize(window.innerWidth, window.innerHeight);

    if (!authenticated) {
      handleAuth();
    } else {
      localStorage.setItem(
        'location',
        this.props.location.pathname + this.props.location.search + this.props.location.hash
      );
    }
  }

  render() {
    const {authenticated} = this.props.AuthStore;
    const me = {}; //this.props.AccountStore.profile;
    const newbie = me.client_id && !me.url;
    const pending = me.status_cd && me.status_cd === 'PENDING';
    if (
      !['/signin', '/signup', '/signout', '/login', '/register', '/logout', '/undefined'].includes(
        this.props.location.pathname
      )
    ) {
      localStorage.setItem(
        'location',
        this.props.location.pathname + this.props.location.search + this.props.location.hash
      );
    }
    return authenticated === null || Promise.resolve(me) === me ? (
      <Loader backgroud={true} />
    ) : pending && !['/pending', '/logout'].includes(this.props.location.pathname) ? (
      <Redirect to="/pending" />
    ) : newbie && ['/'].includes(this.props.location.pathname) ? (
      <Redirect to="/websites/add" />
    ) : (
      <Switch>
        <GuestRoute path="/login" component={Login} layout={LayoutNoHeader} />
        <GuestRoute path="/logout" component={Logout} layout={LayoutDefault} />

        <RestrictedRoute exact path="/" component={Dashboard} authenticated={authenticated} />
        <RestrictedRoute
          exact
          path="/network/:id/:page"
          component={Network}
          authenticated={authenticated}
        />
        <RestrictedRoute
          exact
          path="/networks"
          component={Networks}
          authenticated={authenticated}
        />
        <RestrictedRoute
          exact
          path="/agencies/"
          component={Agencies}
          authenticated={authenticated}
        />
        <RestrictedRoute
          exact
          path="/agency/:id/:page"
          component={Agency}
          authenticated={authenticated}
        />
        <RestrictedRoute
          exact
          path="/advertisers"
          component={Advertisers}
          authenticated={authenticated}
        />

        <GuestRoute component={NoMatch} layout={LayoutDefault} />
      </Switch>
    );
  }
}

export default Routes;
