import {action, observable} from 'mobx';
import {AddressServices} from '../services';
//import {ContactModel} from "../models"

export default class AgencyStore {
	@observable state = 'initial';
	@observable _countries = [];

	constructor(Stores) {
		this.stores = Stores;
		this.service = AddressServices;
	}

	@action
	countries(params = {}) {
		if (this.state === 'fetching') return;
		//this._list = [];
		this.state = 'fetching';

		return this.service.getCountries(params).then(this.fetchSuccess, this.fetchError);
	}

	@action
	regions(params = {}) {
		if (this.state === 'fetching') return;
		//this._list = [];
		this.state = 'fetching';

		return this.service.getRegions(params).then(this.fetchSuccess, this.fetchError);
	}

	@action
	cities(params = {}) {
		if (this.state === 'fetching') return;
		//this._list = [];
		this.state = 'fetching';

		return this.service.getCities(params).then(this.fetchSuccess, this.fetchError);
	}


	@action.bound
	fetchSuccess(res) {
		//TODO: do this with models
		this._list = res;
		this.state = 'initial';
		return this._list;
	}

	@action.bound
	fetchError(error) {
		console.log(error);
		this.state = 'error';
		return this.stores.SystemMessageStore.handleError(error);
	}

}
