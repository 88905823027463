import React, {Component} from "react";
import {MdInfoOutline} from "react-icons/md";

export class QuickTips extends Component {
	constructor(props) {
		super(props);
		this.handleMouseHover = this.handleMouseHover.bind(this);
		this.state = {
			isHovering: false,
		};
	}

	handleMouseHover() {
		this.setState(this.toggleHoverState);
	}

	toggleHoverState(state) {
		return {
			isHovering: !state.isHovering,
		};
	}

	render() {
		return (
			<div className={"quick-tip " + this.props.className}
					 onMouseEnter={this.handleMouseHover}
					 onMouseLeave={this.handleMouseHover}
			>
				<MdInfoOutline/>
				{
					this.state.isHovering &&
					<div className="tips shadow-box">
						{this.props.comment}
					</div>
				}
			</div>
		);
	}
}
