import {inject, observer} from "mobx-react";
import React from 'react';
import {AiOutlineSetting, MdArrowDropDown, MdArrowDropUp, MdPowerSettingsNew} from 'react-icons/all';
import {Link} from "react-router-dom";

export const UserMenu = inject('AccountStore', 'UIStore')(observer((props) => {

	const isOpen = props.UIStore.dropdown.UserMenu;

	const toggleMenu = (e) => {
		if (e)
			e.stopPropagation();
		props.UIStore.toggleDropDown('UserMenu');
		window.addEventListener('click', () => {
			props.UIStore.toggleDropDown('UserMenu', false);
		});
	};

	const me = props.AccountStore.profile;
	return (
		<div className={"profile vertical middle  pl-10 pr-10 " +
		(isOpen ? "opened-menu" : '')}>
			<div className="profile-button vertical middle"
					 onClick={toggleMenu}>
				<p className="sz-14 f-white mr-30   ">
					{me.name}
				</p>
				<span className="sz-12 f-light-purple">ID {me.id}</span>
				<MdArrowDropDown className={"f-white " + (isOpen ? 'hide' : 'show')}/>
				<MdArrowDropUp className={"f-white " + (isOpen ? 'show' : 'hide')}/>
			</div>
			<div className={"profile-dropdown per-100 shadow-box " + (isOpen ? 'show' : 'hide')}>
				<Link to="/settings" onClick={toggleMenu}>
					<AiOutlineSetting/>
					<span>Account Profile</span>
				</Link>
				<Link to="/logout" onClick={toggleMenu}>
					<MdPowerSettingsNew/>
					<span>Logout</span>
				</Link>
			</div>
		</div>
	);
}));
