import {action, computed, observable, toJS} from 'mobx';
import {Summary as Model} from '../models';
import {SummaryServices as Service} from '../services';

export default class NetworkStore {
	@observable state = 'initial';

	@observable merchantSummary = {};
	@observable networkSummary = {};
	@observable agencySummary = {};
	@observable contactSummary = {};

	constructor(Stores) {
		this.stores = Stores;
	}

	@computed
	get list() {
		return [...this._list.values()];
	}

	@computed
	get searchableList() {
		const newList = [];
		this.list.forEach(item => {
			newList.push({
				...item,
				label: item.name,
				value: item.id,
			});
		});
		return newList;
	}

	get item() {
		return toJS(this._item);
	}

	@action
	read({params = {}}) {
		if (this.state === 'fetching') return;
		this.state = 'fetching';
		return Service.get(params).then(this.fetchSuccess, this.fetchError);
	}

	@action.bound
	fetchSuccess(res) {
		if (res.agency) this.agencySummary = new Model(res.agency);
		if (res.contact) this.contactSummary = new Model(res.contact);
		if (res.merchant) this.merchantSummary = new Model(res.merchant);
		if (res.network) this.networkSummary = new Model(res.network);

		this.state = 'done';
		return this._list;
	}

	@action.bound
	fetchError(error) {
		this.state = 'error';
		return this.stores.SystemMessageStore.handleError(error);
	}
}
