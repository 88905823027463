import React from "react";
import {Link} from 'react-router-dom';

export const HistoryCard = (props) => {


	return (
		<div className={"history-card " + props.className}>
			<div className="title">
				<div className="circle"></div>
				<span className="horizon middle left">
         <span>
           {props.result ? props.result : ''}
         </span>
					{props.title}
       </span>
			</div>
			<div className="content">
				<span className="date">12 Jun 2020 | 14:00 PM</span>
				<span className="clients">{props.clients}</span>
				<span className="event">{props.event}</span>
				<Link to="" className="agency">{props.agency}</Link>
			</div>
		</div>
	);
};
