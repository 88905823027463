import React from 'react';

export const AddressFormatter = props => {
	const {city, region, country} = props;
	const array = [];
	if (city) array.push(city.name);
	if (region) array.push(region.name);
	if (country) array.push(country.name);

	return (
		<React.Fragment>
			<span>{array.join(', ')}</span>
		</React.Fragment>
	);
};
