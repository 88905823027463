import {inject, observer} from 'mobx-react';
import moment from 'moment/moment';
import React from 'react';

export const DateTime = inject('AccountStore')(observer((props) => {
	const format = props.format || 'MMM D, YYYY hh:mm A';
	const date = props.timestamp
		? moment.unix(props.timestamp * 0.001)
		: props.native ? moment(props.date) : props.AccountStore.date(props.date);
	return (
		<span>
            {date.isValid() ? date.format(format) : 'N/A'}
    </span>
	);
}));
