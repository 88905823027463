import {inject, observer} from "mobx-react";
import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';

@inject('AuthStore')
@observer
class SignOut extends PureComponent {
	componentDidMount() {
		this.props.AuthStore.signOut();
	}

	render() {
		return <section className="h-100 m-h-auto">
			<div className="full-box one-page-form">
				<div>Sorry to see you go ...{' '}<Link to={process.env.REACT_APP_LOGIN}>back to login</Link></div>
			</div>
		</section>;
	}
}

export default SignOut
