import {AgencyCard, Loader, Pagination} from 'components';
import {SearchInput} from 'materials';
import {inject, observer} from 'mobx-react';
import React from 'react';

export const Agencies = inject('UIStore')(
	observer(props => {
		const {agencies, state, meta} = props;

		return (
			<React.Fragment>
				<div className="card mt-10">
					{state === 'listing' ? (
						<Loader padding/>
					) : (
						<React.Fragment>
							<div className="card-title">
								<h3>Agencies</h3>
								<SearchInput className="card-search"/>
							</div>
							<div className="card-body">
								{/* TABLE DESIGN <table className="list-table per-100">
                <thead>
                  <tr>
                    <td>Agency</td>
                    <td>Web Site</td>
                    <td>Region</td>
                    <td># of Clients</td>
                  </tr>
                </thead>
                <tbody> */}
								<div className="card-body grid col-200">
									{agencies.map(i => <AgencyCard key={'agency_' + i.id} data={i}/>)}
								</div>
								{/* </tbody>
              </table> */}
							</div>
						</React.Fragment>
					)}
				</div>

				{meta.count ? <Pagination
					className="pagination mt-30 mb-40"
					page={(meta.offset / meta.limit) >> 0}
					count={meta.count}
					limit={meta.limit}
					range={9}
				/> : null}
			</React.Fragment>
		);
	})
);
