import {PrimaryButton} from 'materials';
import qs from 'query-string';
import React from 'react';
import {useForm} from 'react-hook-form';
import {BsFilterLeft} from 'react-icons/all';
import {useHistory} from 'react-router-dom';
import {Multiselect} from './Multiselect';
import {agencyMap, networkMap, networkTypeMap, officesMap, regionsMap, sectorsMap} from './OptionsData';

export const Filter = props => {
	const {register, handleSubmit} = useForm();

	const {withNetwork, withAgency, withRegions, withOffices, withSectors, withNetworkType} = props;
	const history = useHistory();

	const onSubmit = data => {
		const params = qs.stringify(data);
		history.push('?' + params)
	};

	return (
		<div className="card p-0 filter-card">
			<div className="card-title horizon stretch p-0 border-none">
				<div className="card-icon">
					<BsFilterLeft/>
				</div>
				<h2 className="sz-16 fw-500 pl-6 horizon left">Filter</h2>
			</div>

			<form className="per-100" onSubmit={handleSubmit(onSubmit)} noValidate={true}>
				{
					withNetwork && (
						<Multiselect
							header="Network"
							options={networkMap}
							paramKey="network"
							register={register}
						/>
					)
				}

				{
					withAgency && (
						<Multiselect
							header="Agency"
							options={agencyMap}
							paramKey="agency"
							register={register}
						/>
					)
				}

				{withRegions && (
					<Multiselect
						header="Regions"
						options={regionsMap}
						paramKey="regions"
						register={register}
					/>
				)}

				{withOffices && (
					<Multiselect
						header="Offices"
						options={officesMap}
						paramKey="offices"
						register={register}
						autoComplete
					/>
				)}

				{withSectors && (
					<Multiselect
						header="Sectors"
						options={sectorsMap}
						paramKey="sectors"
						register={register}
						autoComplete
					/>
				)}

				{withNetworkType && (
					<Multiselect
						header="Multiple Networks"
						options={networkTypeMap}
						paramKey="networkType"
						register={register}
					/>
				)}

				<div className="section">
					<PrimaryButton type="submit" name="Filter" className="check per-100 horizon center"/>
				</div>
			</form>
		</div>
	);
};
