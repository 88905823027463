import {action, computed, observable} from 'mobx';
import {Agency as Model,} from '../models';
import {AgencyServices as Service} from '../services';

export default class NewAgencyStore {
	@observable state = 'initial';
	@observable _list = new observable.map();

	constructor(Stores) {
		this.stores = Stores;
	}

	@computed
	get list() {
		return [...this._list.values()];
	}

	@action
	read({params = {}}) {
		if (this.state === 'done') return;
		return Service.readNews(params).then(this.fetchSuccess, this.fetchError);
	}

	@action.bound
	fetchSuccess(res) {
		this._list = new observable.map();
		res.forEach(i => {
			const item = new Model(i || {});
			this._list.set(item.id, item);
		});
		this.state = 'done';
		return this._list;
	}

	@action.bound
	fetchError(error) {
		this.state = 'error';
		return this.stores.SystemMessageStore.handleError(error);
	}
}
