import {TinyId, WebsiteOpener} from 'components';
import {inject, observer} from 'mobx-react';
import React from 'react';
import {Link} from 'react-router-dom';

export const Agency = inject('UIStore')(
	observer(props => {
		const {id, name, website_logo, merchant_count, url_list} = props.data;
		return (
			<tr>
				<td>
					<TinyId
						logo={website_logo}
						name={name}
						to={"/agency/" + id + '/clients'}
					/>
				</td>
				<td>
					<WebsiteOpener url={(url_list || [])[0]} limit='20'/>
				</td>
				{/* <td>-</td> */}
				<td>
					<Link to={"/agency/" + id + '/clients'} className="p-0 text-underline">{merchant_count}</Link>
				</td>
			</tr>
		);
	})
);
