import {AppliedFilters, Filter, Loader, Pagination} from 'components';
import {SearchInput} from 'materials';
import {inject, observer} from 'mobx-react';
import qs from 'query-string';
import React, {useEffect} from 'react';
import {FaExternalLinkAlt} from 'react-icons/all';
import {useLocation} from 'react-router-dom';
import {Item} from './Item';

export const Advertisers = inject('MerchantStore')(
	observer(props => {
		const location = useLocation();

		useEffect(() => {
			const searchParams = qs.parse(location.search);
			props.MerchantStore.read({params: searchParams});
		}, [props.MerchantStore, location.search]);

		const {list, meta, state} = props.MerchantStore;
		return (
			<section className="vertical center">
				<div className="page-head network-single">
					<div className="container">
						<div className="page-title">
							<h3 className="horizon middle">
								<FaExternalLinkAlt className="mr-6"/>
								Advertisers
							</h3>
							<SearchInput/>
						</div>
					</div>
				</div>
				{state === 'listing' ? (
					<Loader/>
				) : (
					<div className="container page-body">
						<div className="right">
							<Filter withNetwork withAgency withRegions withSectors withNetworkType/>
						</div>
						<div className="left">
							<div className="card">
								<div className="result-head">
									<div className="title">
										<div className="result-content">
											we found <span>{meta.count}</span> unique results related to the advertisers.
										</div>
									</div>
								</div>

								<AppliedFilters/>

								<div className="per-100">
									<table className="list-table">
										<thead>
										<tr>
											<td>Advertiser
											</td>
											<td>Affiliate Network</td>
											<td>Affiliate Agency</td>
											{/* <td>Markets</td> */}
											<td>Sector</td>
										</tr>
										</thead>
										<tbody>
										{/* Her merchantın
                      Her websiteını
                      Her network u için ayrı ayrı yazırmak gerek
                      */}
										{list.map(advertiser =>
											advertiser.websites.length ?
												advertiser.websites.map(website =>
													website.networks.length ? (
														website.networks.map(network => (
															<Item
																key={`advertiser_card_${website.id}_${network.id}`}
																advertiserName={advertiser.name}
																advertiserLogo={advertiser.website_logo}
																sectors={advertiser.sectors}
																url={website.url}
																network={network}
																agency={website.agency}
															/>
														))
													) : (
														<Item
															key={`advertiser_card_${website.id}`}
															advertiserName={advertiser.name}
															sectors={advertiser.sectors}
															url={website.url}
															agency={website.agency}
														/>
													)
												)
												: <Item
													key={`advertiser_card_no_website_${advertiser.id}`}
													advertiserName={advertiser.name}
													sectors={advertiser.sectors}
													url={''}
													agency={''}
												/>
										)}
										</tbody>
									</table>
								</div>
							</div>

							<Pagination
								className="pagination mt-30 mb-40"
								page={(meta.offset / meta.limit) >> 0}
								count={meta.count}
								limit={meta.limit}
								range={9}
							/>
						</div>
					</div>
				)}
			</section>
		);
	})
);
