import {action, computed, observable, toJS} from 'mobx';
import {Agency as Model, AgencyMeta as Meta} from '../models';
import {AgencyServices as Service} from '../services';

export default class AgencyStore {
	_limit = 15;
	@observable state = 'initial';
	@observable meta = {};
	@observable _list = new observable.map();
	@observable _item = {};

	constructor(Stores) {
		this.stores = Stores;
	}

	get list() {
		return [...this._list.values()];
	}

	// @action
	// createNewAgency = async data => {
	//   if (!data) return false;
	//   return await Service
	//     .createNewAgency(data)
	//     .then(async res => {
	//       // const item = new Model(res || {})
	//       // this._list.set(item.id, item);
	//       //this.read({});
	//       return res;
	//     })
	//     .catch(error => {
	//       console.log('new agency error');
	//       this.stores.SystemMessageStore.handleError(error);
	//     });
	// };

	// @action
	// addNewOffice = async (data, id) => {
	//   if (!data || !id) return false;
	//   return await Service
	//     .addNewOffice(data, id)
	//     .then(async res => {
	//       const item = new Model(res || {})
	//       this._list.set(item.id, item);
	//       return res;
	//     })
	//     .catch(error => {
	//       console.log('new office error - agency');
	//       this.stores.SystemMessageStore.handleError(error);
	//     });
	// };

	// @action
	// addNewContact = async (data, id) => {
	//   if (!data || !id) return false;
	//   return await Service
	//     .addNewContact(data, id)
	//     .then(async res => {
	//       const item = new Model(res || {})
	//       this._list.set(item.id, item);
	//       return res;
	//     })
	//     .catch(error => {
	//       console.log('new contact error - network');
	//       this.stores.SystemMessageStore.handleError(error);
	//     });
	// };

	get item() {
		return toJS(this._item);
	}

	@computed
	get searchableList() {
		const newList = [];
		this.list.forEach(item => {
			newList.push({
				...item,
				label: item.name,
				value: item.id,
			});
		});
		return newList;
	}

	@action
	read({params = {}, id = null}) {
		if (id) {
			this.state = 'getting';
			return Service.detail(id).then(res => {
				this._item = new Model(res || {})
				this.state = 'done';
			});
		} else {
			if (this.state === 'listing') return;
			this.state = 'listing';
			return Service.read({limit: this._limit, ...params}).then(this.fetchSuccess, this.fetchError);
		}
	}

	@action.bound
	fetchSuccess(res) {
		if (res.results) {
			const prev = res.previous;
			const prevParams = new URLSearchParams(prev);
			const limit = prevParams.get('limit') || this._limit;
			const offset = prev ? Number(prevParams.get('offset')) + Number(limit) : 0;
			this.meta = new Meta({count: res.count, limit, offset});
			this._list = new observable.map();
			res.results.forEach(i => {
				const item = new Model(i || {})
				this._list.set(item.id, item)
			})
		}
		this.state = 'done';
		return !!this._list;
	}

	@action.bound
	fetchError(error) {
		this.state = 'error';
		return this.stores.SystemMessageStore.handleError(error);
	}
}
