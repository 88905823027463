import React, {Component} from "react";

export class CheckButton extends Component {
	render() {
		return (
			<button id={this.props.id} className='btn check ml-6'>
				{this.props.name}
			</button>
		);
	}
}
