import {CheckBox} from 'materials';
import React, {useState} from 'react';
import {MdSearch} from 'react-icons/md';
import {useLocation} from 'react-router-dom';
import {firstBy} from 'thenby';

export const Multiselect = props => {
	const {options, register, autoComplete, paramKey, header} = props;
	const [input, setInput] = useState('');

	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const selectedParams = params.getAll(paramKey);

	const [selectedOptions, setSelected] = useState(selectedParams);

	const handleInputChange = e => {
		const val = e.target.value;
		setInput(val);
	};

	const handleCheckboxChange = e => {
		const items = [...selectedOptions];
		if (e.target.checked) items.push(e.target.value);
		else items.splice(items.indexOf(e.target.value), 1);

		setSelected(items);
	};

	const mapToCheckboxes = allOptions => {
		const ordered = {};

		Object.entries(allOptions)
			.sort(
				firstBy(
					(a, b) => selectedOptions.includes(b[0]) - selectedOptions.includes(a[0])
				).thenBy((a, b) => a[1].localeCompare(b[1]))
			)
			.forEach(i => {
				ordered[i[0]] = i[1];
			});

		return Object.entries(ordered).map(([key, label]) => (
			<CheckBox
				key={paramKey + key}
				label={label}
				name={paramKey}
				value={key}
				ref={register({})}
				onChange={handleCheckboxChange}
				checked={selectedOptions.includes(key)}
				style={{
					display: label.toLowerCase().includes(input.toLowerCase()) ? 'block' : 'none',
				}}
			/>
		));
	};

	return (
		<div className="section">
			{header && <p>{header}</p>}
			{autoComplete ? (
				<div className="autocomplete-search-area per-100">
					<div className="search-input">
						<input type="text" onChange={handleInputChange} value={input}/>
						<span>
              <MdSearch/>
            </span>
					</div>
					<div id="scrollBar" className="items-area">
						{mapToCheckboxes(options)}
					</div>
				</div>
			) : (
				<React.Fragment>{mapToCheckboxes(options)}</React.Fragment>
			)}
		</div>
	);
};
