import React, {useEffect, useState} from "react";
import {MdArrowDropDown} from "react-icons/md";

export const SelectBox = React.forwardRef((props, ref) => {
	const [value, setValue] = useState(props.defaultValue || props.value || '');
	const errors = props.errors || {};
	const messages = props.messages || {};
	const [className, setClassName] = useState(props.className || '');

	useEffect(() => {
		setValue(props.defaultValue || props.value)
	}, [props]);

	const handleFocus = (e) => {
		setClassName(className.replace(/ focused/g, '') + ' focused')
	};
	const handleBlur = (e) => {
		const el = e.currentTarget;
		const value = el.value;
		if (!value.length)
			setClassName(className.replace(/ focused/g, ''))
	};
	const handleChange = (e) => {
		const el = e.currentTarget;
		const value = el.value;
		if (value) {
			setClassName(className.replace(/ focused/g, '') + ' focused')
		}
		setValue(value);
		if (props.hasOwnProperty('onChange'))
			props.onChange(e)
	};
	return (
		<React.Fragment>
			<label className='per-100'>
				<div
					className="border-box per-100 select-box horizon between middle">
					<select className={className} name={props.name} value={value}
									onChange={handleChange}
									onFocus={handleFocus}
									onBlur={handleBlur}
									disabled={props.disabled}
									ref={ref}
					>
						{props.placeholder ? <option value={''}>{props.placeholder}</option> : null}
						{props.options || props.children}
					</select>
					<MdArrowDropDown/>
				</div>
			</label>
			{errors[props.name] && <div className="alert-box error per-100">
				<p>{messages[errors[props.name].type]}</p></div>}
		</React.Fragment>
	);
});
