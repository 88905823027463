import {request} from 'helpers';

export class Services {
	static read = async params => {
		const getRes = await request.get('/merchants/meta/', {...params}, true);
		//console.log("getRes",{getRes})
		return getRes;
	};

	static readNews = async params => {
		const getRes = await request.get('/merchants/new_creations/', {...params}, true);
		//console.log("getRes",{getRes})
		return getRes;
	};

	static createNewMerchant = async data => {
		const createNewMerchantRes = await request.post('/merchants/', data);
		//console.log({createNewMerchantRes})
		return createNewMerchantRes;
	};

	static addNewUrl = async (data, id) => {
		const addNewUrlRes = await request.post(`/merchants/${id}/add_website/`, data);
		//console.log({createNewMerchantRes})
		return addNewUrlRes;
	}

	static linkContact = async (publisherID, contactID) => {
		const linkContact = await request.post(`/websites/${publisherID}/link_contact/`, {contact: contactID});
		//console.log({linkContact})
		return linkContact;
	}

	static detail = async id => {
		return await request.get('/merchants/' + id + '/', {}, true);
	}
}
