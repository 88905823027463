import React, {Component} from "react";


export class MoreLess extends Component {
	constructor(props) {
		super(props);
		this.handleMouseHover = this.handleMouseHover.bind(this);
		this.state = {
			moreContent: false,
		}
	}

	handleMouseHover() {
		this.setState(this.toggleHoverState);
	}

	toggleHoverState(state) {
		return {
			moreContent: !state.moreContent,
		};
	}

	render() {
		return (
			<div className='more-less'
					 onMouseEnter={this.handleMouseHover}
					 onMouseLeave={this.handleMouseHover}
					 onClick={this.props.onClick}
			>
				{
					this.state.moreContent && this.props.content &&
					this.props.content.length > this.props.maxLimit ?
						<div className={
							'full-content shadow-box ' + this.props.openBox
						}>
							{this.props.content}
						</div>
						: null
				}
				{this.props.content &&
				(this.props.content.length > this.props.maxLimit ?
						this.props.content.slice(0, this.props.maxLimit) + '...'
						: this.props.content.slice(0, this.props.maxLimit)
				)}
			</div>
		);
	}


}
