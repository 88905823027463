import {action, observable} from 'mobx';
import {DetailAgency as Model, DetailAgencyMeta as Meta} from '../models';
import {CommonServices as Service} from '../services';

export default class DetailAgencyStore {
	_limit = 16;
	@observable state = 'initial';
	@observable meta = {};
	@observable _list = new observable.map();

	constructor(Stores) {
		this.stores = Stores;
	}

	get list() {
		return [...this._list.values()];
	}

	@action
	read({page, id, params}) {
		if (this.state === 'listing') return;
		this.state = 'listing';
		return Service.agencies({
			id,
			page,
			params: {...params, limit: this._limit}
		}).then(this.fetchSuccess, this.fetchError);
	}

	@action.bound
	fetchSuccess(res) {
		if (res) {
			const prev = res.previous;
			const prevParams = new URLSearchParams(prev);
			const limit = prevParams.get('limit') || this._limit;
			const offset = prev ? Number(prevParams.get('offset')) + Number(limit) : 0;
			this.meta = new Meta({count: res.count, limit, offset});
			// this.meta = new Meta({count: res.length, limit: res.length, offset: 0})
			this._list = new observable.map();
			res.results.forEach(i => {
				const item = new Model(i || {})
				this._list.set(item.id, item)
			})
		}
		this.state = 'done';
	}

	@action.bound
	fetchError(error) {
		this.state = 'error';
		return this.stores.SystemMessageStore.handleError(error);
	}
}
