import {WebsiteOpener} from 'components';
import React from 'react';
import LazyLoad from 'react-lazyload';
import {useHistory} from 'react-router-dom';

export const NetworkCard = props => {
	const {data} = props;
	const history = useHistory();

	return (
		<div className="network-card" onClick={() => history.push('/network/' + data.id + '/clients')}>
			<div className="vertical top lef per-100 p-10 border-box">
				<div className="title">
					<LazyLoad>
						<div className="logo">
							{data.website_logo ? (
								<img
									alt=""
									src={
										data.website_logo.startsWith('http')
											? data.website_logo
											: '//' + data.website_logo
									}
								/>
							) : (
								<img alt="" src={require('../../assets/images/no-logo.png')}/>
							)}
						</div>
					</LazyLoad>

					<div className="content">
						<p>{data.name}</p>
						<WebsiteOpener url={data.url_list && data.url_list[0]} limit="20"/>
					</div>
				</div>

				<div className="item">
					<span>Region</span>
					<span>?</span>
					{/* {data.merchant ? <span>: {data.merchant}</span> : <span>: N/A</span>} */}
				</div>
				<div className="item">
					<span>Office</span>
					<span>{data.office_count}</span>
				</div>
				<div className="item">
					<span># of Total Clients</span>
					{data.merchant_count ? (
						<span>{data.merchant_count}</span>
					) : (
						<span>0</span>
					)}
				</div>
				<div className="item">
					<span># of Agencies</span>
					<span>{data.agency_count}</span>
				</div>
			</div>
			{/*<div className="counts horizon between per-100 ">*/}
			{/*  <div className="item">*/}
			{/*    <span># of Total Clients</span>*/}
			{/*    {data.merchant_count ? (*/}
			{/*      <span>{data.merchant_count}</span>*/}
			{/*    ) : (*/}
			{/*      <span>0</span>*/}
			{/*    )}*/}
			{/*  </div>*/}
			{/*  <div className="item">*/}
			{/*    <span># of Agencies</span>*/}
			{/*    <span>{data.agency_count}</span>*/}
			{/*    /!* {data.agency ? <Link to="">{data.agency.length}</Link> : <p>N/A</p>} *!/*/}
			{/*  </div>*/}
			{/*</div>*/}
		</div>
	);
};