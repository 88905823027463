import {request} from 'helpers';

export class Services {
	static read = async params => {
		const getRes = await request.get('/agencies/meta/', {...params}, true);
		//console.log("getRes",{getRes})
		return getRes;
	};

	static detail = async (id) => {
		const getRes = await request.get('/agencies/' + id + '/', {}, true);
		//console.log("getRes",{getRes})
		return getRes;
	};

	static readNews = async params => {
		const getRes = await request.get('/agencies/new_creations/', {...params}, true);
		//console.log("getRes",{getRes})
		return getRes;
	};

	static trending = async params => {
		const getRes = await request.get('/agencies/trending_list/', {...params}, true);
		//console.log("getRes",{getRes})
		return getRes;
	};

	static createNewAgency = async data => {
		const createNewAgencyRes = await request.post('/agencies/', data);
		//console.log({createNewAgenyRes})
		return createNewAgencyRes;
	};

	static addNewOffice = async (data, id) => {
		const addNewOfficeRes = await request.post(`/agencies/${id}/add_office/`, data);
		//console.log({createNewMerchantRes})
		return addNewOfficeRes;
	}

	static addNewContact = async (data, id) => {
		const addNewContactRes = await request.post(`/agencies/${id}/add_contact/`, data);
		//console.log({createNewMerchantRes})
		return addNewContactRes;
	};
}
