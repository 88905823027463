import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {ScrollToTop} from "./components";
import Routing from './routing';

const App = () =>
	<Router>
		<ScrollToTop/>
		<Switch>
			<Route path="/" component={Routing}/>
		</Switch>
	</Router>;

export default App;
