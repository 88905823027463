import {LargeId, Loader} from 'components';
import {inject, observer} from 'mobx-react';
import qs from 'query-string';
import React, {useEffect} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {Clients} from '../Agencies/Clients/Clients';
import {Agencies} from './Agencies/Agencies';
import {Overview} from './Overview';

export const Network = inject('NetworkStore', 'DetailClientStore', 'DetailAgencyStore')(
	observer(props => {
		const params = useParams();
		const history = useHistory();
		const location = useLocation();
		const {id, page} = params;
		const defaultIndex = page === 'clients' ? 0 : 1;

		useEffect(() => {
			props.NetworkStore.read({id});
		}, [id, props.NetworkStore]);
		const {item, state} = props.NetworkStore;

		useEffect(() => {
			const searchParams = qs.parse(location.search);
			props.DetailClientStore.read({id, page: 'networks', params: searchParams});
		}, [props.DetailClientStore, id, location.search])
		const {list: clientList, state: clientState, meta: clientMeta} = props.DetailClientStore;

		useEffect(() => {
			const searchParams = qs.parse(location.search);
			props.DetailAgencyStore.read({id, page: 'networks', params: searchParams});
		}, [props.DetailAgencyStore, id, location.search])
		const {list: agencyList, state: agencyState, meta: agencyMeta} = props.DetailAgencyStore;

		const handleTabChange = (newTab) => {
			newTab === 0 ? history.push(`/network/${id}/clients`) : history.push(`/network/${id}/agencies`)
		}

		return (
			<React.Fragment>
				{state === 'getting' ? (
					<Loader/>
				) : (
					<section className="vertical center">
						<div className="page-head network-single">
							<div className="container">
								<div className="page-title">
									<LargeId
										logo={item.website_logo}
										company={item.name}
										url={item.url}
									/>
								</div>
							</div>
						</div>

						<div className="container page-body">
							<Tabs className="left" defaultIndex={defaultIndex} onSelect={handleTabChange}>
								<TabList className="tab-links">
									<Tab>Clients</Tab>
									<Tab>Agencies</Tab>
								</TabList>
								<TabPanel>
									<Clients clients={clientList} state={clientState} meta={clientMeta}/>
								</TabPanel>
								<TabPanel>
									<Agencies agencies={agencyList} state={agencyState} meta={agencyMeta}/>
								</TabPanel>
							</Tabs>

							<div className="right">
								<Overview item={item} clientCount={clientMeta.count} agencyCount={agencyMeta.count}/>
							</div>
						</div>
					</section>
				)}
			</React.Fragment>
		);
	})
);
