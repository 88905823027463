import {SummaryCard} from 'components';
import {inject, observer} from 'mobx-react';
import React, {useEffect} from 'react';

export const Summary = inject('SummaryStore')(
	observer(props => {
		useEffect(() => {
			props.SummaryStore.read({});
		}, [props.SummaryStore]);
		const {agencySummary, networkSummary, merchantSummary} = props.SummaryStore;

		return (
			<div className="summary-base horizon between">
				<SummaryCard title="# of Network" count={networkSummary.count} data={networkSummary.data}/>
				<SummaryCard
					title="# of Advertisers"
					count={merchantSummary.count}
					data={merchantSummary.data}
				/>
				<SummaryCard
					title="# of Agencies"
					count={agencySummary.count || 0}
					data={agencySummary.data}
				/>
			</div>
		);
	})
);
