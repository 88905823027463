export const networkMap = {
	CJ: 'CJ',
	RAKUTEN: 'Rakuten Advertising'
}

export const agencyMap = {
	OpmPros: 'OPM Pros',
	NightAgency: 'Night Agency'
}

export const regionsMap = {
	FR: 'France',
	CA: 'Canada',
	UK: 'United Kingdom',
	US: 'United States',
};

export const officesMap = {
	los_angles: 'Los Angles',
	brooklyn: 'Brooklyn',
	new_york: 'New York',
	chicago: 'Chicago',
	houston: 'Houston',
	philadelphia: 'Philadelphia',
	san_antonio: 'San Antonio',
	san_diego: 'San Diego',
};

export const sectorsMap = {
	computer: 'Computer',
	software: 'Software',
	electronic: 'Electronic',
	marketing: 'Marketing',
	shoes: 'Shoes',
	bag: 'Bag',
	game: 'Game',
	cosmetic: 'Cosmetic',
};

export const networkTypeMap = {
	all: 'All',
	direct: 'Direct',
	single: 'Single',
	multiple: 'Multiple',
};
