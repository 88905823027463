import React from 'react';
import LazyLoad from 'react-lazyload';
import {Link} from 'react-router-dom';

export const LargeId = props => {
	return (
		<div className="large-id-card">
			<LazyLoad>
				<div className="logo">
					{props.logo ? (
						<img alt="" src={props.logo.startsWith('http') ? props.logo : '//' + props.logo}/>
					) : (
						<img alt="" src={require('../../assets/images/no-logo.png')}/>
					)}
				</div>
			</LazyLoad>
			<div className="content">
				<h3>{props.company}</h3>
				<Link to="">{props.url}</Link>
			</div>
		</div>
	);
};
