import {Loader} from 'components';
import {inject, observer} from 'mobx-react';
import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Agency} from '../NewAgencies/Agency';

export const TrendingAgencies = inject('TrendingAgencyStore')(
	observer(props => {
		useEffect(() => {
			props.TrendingAgencyStore.read({});
		}, [props.TrendingAgencyStore]);

		const {list, state} = props.TrendingAgencyStore;
		return (
			<div className="card per-100 mt-10">
				<div className="card-title">
					<h3>Trending Agencies</h3>
				</div>
				<div className="card-body">
					{state !== 'done' ? (
						<Loader padding/>
					) : (
						<table className="list-table">
							<thead>
							<tr>
								<td>Agency Name</td>
								<td>Agency URL</td>
								{/* <td>Markets</td> */}
								<td># of Clients</td>
							</tr>
							</thead>
							<tbody>
							{list.map(i => (
								<Agency
									key={'trending_agencies_' + i.id}
									data={i}/>
							))}
							</tbody>
						</table>
					)}
				</div>
				<div className="card-action p-10 horizon center">
					<Link className="sz-12" to="/agencies">
						Show All Agencies
					</Link>
				</div>
			</div>
		);
	})
);
