import {Loader} from 'components';
import {inject, observer} from 'mobx-react';
import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Advertiser} from './Advertiser';

export const NewAdvertisers = inject('NewMerchantStore')(
	observer(props => {
		useEffect(() => {
			props.NewMerchantStore.read({});
		}, [props.NewMerchantStore]);

		const {list, state} = props.NewMerchantStore;

		// merchant.networks.length ? (
		//   merchant.networks.map(i => <Advertiser key={`new_advertisers_${merchant.id}_${i.id}`} metaData={merchant} networkData={i} />)
		// ) :

		return (
			<React.Fragment>
				<div className="card per-100 mt-10">
					<div className="card-title">
						<h3>New Advertisers</h3>
					</div>
					<div className="card-body">
						{state !== 'done' ? (
							<Loader padding={true}/>
						) : (
							<table className="list-table">
								<thead>
								<tr>
									<td>Advertiser Name</td>
									<td>Affiliate Network</td>
									{/* <td>Markets</td> */}
									<td>Sector</td>
								</tr>
								</thead>
								<tbody>
								{list.map(merchant => (
									<Advertiser
										key={'new_advertiser_' + merchant.id}
										data={merchant}
										networkData={{}}
									/>
								))}
								</tbody>
							</table>
						)}
					</div>
					<div className="card-action p-10 horizon center">
						<Link className="sz-12" to="/advertisers">
							Show All Advertisers
						</Link>
					</div>
				</div>
			</React.Fragment>
		);
	})
);
