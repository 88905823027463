import {inject, observer} from 'mobx-react';
import React, {useState} from 'react';
import {MdClose} from 'react-icons/md';

export const Sectors = inject('UIStore')(
	observer(props => {
		const {sectors} = props;
		const [popUp, setPopup] = useState(false);

		const handleClick = () => {
			setPopup(!popUp);
		};
		return (
			<div className="sector-modal">
				<span onClick={handleClick}>{(sectors || []).length}</span>

				{popUp && (
					<div className={'modal-form ' + props.className}>
						<div className="modal-form-container small-size">
							<div className="modal-form-title horizon between middle per-100 ">
								<h3>Sectors</h3>
								<MdClose onClick={handleClick}/>
							</div>
							<div className=" per-100 border-box  ">
								<div id="scrollBar" className="per-100  vertical between scroll-base">
									<table className="list-table">
										<tbody>
										{(sectors || []).map(i =>
											<tr>
												<td>{i.name}</td>
											</tr>
										)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	})
);
