import React from "react";
import {MdSearch} from 'react-icons/md';

export const AutocompleteSearchArea = React.forwardRef((props, ref) => {

	return (
		<div className="autocomplete-search-area per-100">
			<div className="search-input">
				<input type="text"/>
				<span>
          <MdSearch/>
        </span>
			</div>
			<div id="scrollBar" className="items-area">
				{props.children}
			</div>
		</div>
	);
});
