import {action, computed, observable, toJS} from 'mobx';
import {Contact as Model, ContactMeta as Meta} from '../models';
import {ContactServices as Service} from '../services';

export default class ContactStore {
	_limit = 10;
	@observable state = 'initial';
	@observable meta = {};
	@observable _list = new observable.map();
	@observable _item = {};

	enums = {
		linkedin: 'LinkedIn',
		affiliate_interface: 'Affiliated Interface',
		email: 'E-mail',
		newsletter: 'Newsletter',
		null: '',
	};
	enumsSearchable = [
		{
			label: 'LinkedIn',
			value: 'linkedin'
		},
		{
			label: 'Affiliate Interface',
			value: 'affiliate_interface'
		},
		{
			label: 'E-Mail',
			value: 'email'
		},
		{
			label: 'Newsletter',
			value: 'newsletter'
		},
	]

	constructor(Stores) {
		this.stores = Stores;
	}

	@computed
	get list() {
		return [...this._list.values()];
	}

	get item() {
		return toJS(this._item);
	}

	get getEnums() {
		return this.enums;
	}

	get getSearchableEnums() {
		return this.enumsSearchable;
	}

	@action
	read({params = {}, id = null}) {
		if (id) {
			this.state = 'getting';
			return Service.detail(id).then(res => {
				this._item = new Model(res || {})
				this.state = 'done';
			});
		} else {
			if (this.state === 'listing') return;
			this.state = 'listing';
			return Service.get({limit: this._limit, ...params}).then(this.fetchSuccess, this.fetchError);
		}
	}

	@action.bound
	fetchSuccess(res) {
		//TODO: do this with models
		if (res.results) {
			const prev = res.previous;
			const prevParams = new URLSearchParams(prev);
			const limit = prevParams.get('limit') || this._limit;
			const offset = prev ? Number(prevParams.get('offset')) + Number(limit) : 0;
			this.meta = new Meta({count: res.count, limit, offset});
			this._list = new observable.map();
			res.results.forEach(i => {
				const item = new Model(i || {})
				this._list.set(item.id, item)
			})
		}
		this.state = 'done';
		return this._list;
	}

	@action.bound
	fetchError(error) {
		console.log(error);
		this.state = 'error';
		return this.stores.SystemMessageStore.handleError(error);
	}

}
