import base64 from 'base-64';
import {request} from 'helpers';

export class Services {
	static createUser = async data => {
		return await request.post('/account/create', data, false);
	};

	static create = async data => {
		return await request.post('/users', data);
	};

	static get = async params => {
		return await request.get('/users', params);
	};

	static detail = async id => {
		if (id) return await request.get('/users/' + id);
	};

	static update = async (id, data) => {
		try {
			const res = await request.put('/users/' + id, data).then();
			return res;
		} catch (e) {
			throw e;
		}
	};

	static updateProfile = async data => {
		try {
			const res = await request.put('/account/profile', data).then();
			return res;
		} catch (e) {
			throw e;
		}
	};

	static delete = async (url_id, id) => {
		try {
			return await request.delete('/users/' + id, {url_id}).then();
		} catch (e) {
			throw e;
		}
	};

	static signIn = async ({email, password, remember}) => {
		try {
			const res = await request.post('/login/', {email, password}, false).then();
			const headers = new Headers();
			headers.set('Content-Type', 'application/x-www-form-urlencoded');
			headers.set(
				'Authorization',
				'Basic ' + base64.encode(res.client_id + ':' + res.client_secret)
			);

			const body = `grant_type=client_credentials`;
			const token = await request.post('/o/token/', body, false, headers, true).then();

			//console.log(token);
			request.setCookies({
				access_token: token.access_token,
				remember,
				client_id: res.client_id,
				client_secret: res.client_secret,
				expires_in: token.expires_in,
				email
			});

			return true; // return true;
		} catch (e) {
			console.log(e)
			throw e;
		}
	};

	static signOut = () => request.deleteCookies();

	static reset = async data => {
		return await request.post('/auth/forgot-password', data, false).then();
	};

	static newPassword = async data => {
		return await request.post('/auth/confirm-forgot-password', data, false);
	};

	static changePassword = async data => {
		return await request.post('/auth/change-password', data);
	};

	static activation = async data => {
		return await request.post('/account/activation', data, false);
	};

	static verify = async data => {
		return await request.post('/verify-email', data, false);
	};

	static newsletter = async data => {
		return await request.post('/newsletter', data, false);
	};

	static getProfile = async () => {
		return await request.get('/account/profile', null, true);
	};
}
