import {request} from 'helpers';

export class Services {
	static get = async (params) => {
		const getRes = await request.get('/networks/meta/', {...params}, true);
		//console.log("getRes",{getRes})
		return getRes;
	};

	static detail = async (id) => {
		const getRes = await request.get('/networks/' + id + '/', {}, true);
		//console.log("getRes",{getRes})
		return getRes;
	};

	static createNewNetwork = async data => {
		return await request.post('/networks/', data);
	};

	static addNewOffice = async (data, id) => {
		const addNewOfficeRes = await request.post(`/networks/${id}/add_office/`, data);
		//console.log({createNewMerchantRes})
		return addNewOfficeRes;
	}

	static addNewContact = async (data, id) => {
		const addNewContactRes = await request.post(`/networks/${id}/add_contact/`, data);
		//console.log({createNewMerchantRes})
		return addNewContactRes;
	};
}
