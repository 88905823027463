import React, {Component} from "react";

export class TopKeywordsTable extends Component {
	render() {
		return (
			<table className="modal-table">
				<thead>
				<tr>
					<td>No</td>
					<td>Keywords</td>
					<td>Count</td>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td>1</td>
					<td>free coupon 2019</td>
					<td>123</td>
				</tr>
				<tr>
					<td>2</td>
					<td>free coupon 2019</td>
					<td>123</td>
				</tr>
				<tr>
					<td>3</td>
					<td>free coupon 2019</td>
					<td>123</td>
				</tr>
				<tr>
					<td>4</td>
					<td>free coupon 2019</td>
					<td>123</td>
				</tr>
				<tr>
					<td>5</td>
					<td>free coupon 2019</td>
					<td>123</td>
				</tr>
				</tbody>
			</table>
		);
	}
}
