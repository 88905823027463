import {Sectors, TinyId} from 'components';
import React from 'react';

export const Item = props => {
	const {advertiserName, advertiserLogo, sectors, url, network, agency} = props;

	return (
		<tr>
			<td>
				<TinyId
					logo={advertiserLogo}
					name={advertiserName}
					url={url}
				/>
			</td>

			<td>
				{network ? (
					<TinyId
						logo={network.website_logo}
						name={network.name}
						to={'/network/' + network.id + '/clients'}
					/>
				) : (
					<span>-</span>
				)}
			</td>
			<td>
				{agency ? (
					<TinyId
						logo={agency.website_logo}
						name={agency.name}
						to={'/agency/' + agency.id + '/clients'}
					/>
				) : (
					<span>-</span>
				)}
			</td>
			{/* <td>-</td> */}
			<td><Sectors sectors={sectors}/></td>
		</tr>
	);
};
