export class Meta {
	constructor(data) {
		if (data)
			Object.entries(data).forEach(([k, i]) => this[k] = i)
	}
}

export class Agency {
	networksCount = 0;

	constructor(data) {
		if (data)
			Object.entries(data).forEach(([k, i]) => this[k] = i)
	}

	// get id() {
	//   return this['user_id']
	// }

	get networks() {
		if (!this.websites) return {};
		const uniqueNetworks = {}

		this.websites.forEach(website => website.networks.forEach(network => {
			if (network) uniqueNetworks[network.id] = network;
		}))

		// this.websites.forEach(website => {
		//   if(website.agency) agencies[website.agency.id] = website.agency;
		// })

		this.networksCount = Object.keys(uniqueNetworks).length
		return uniqueNetworks;
	}

}