import {AppliedFilters, Filter, Loader, NetworkCard, Pagination} from 'components';
import {SearchInput} from 'materials';
import {inject, observer} from 'mobx-react';
import qs from 'query-string';
import React, {useEffect} from 'react';
import {FaNetworkWired} from 'react-icons/all';
import {useLocation} from 'react-router-dom';

export const Networks = inject('NetworkStore')(
	observer(props => {
		const location = useLocation();

		useEffect(() => {
			const searchParams = qs.parse(location.search);
			props.NetworkStore.read({params: searchParams});
		}, [props.NetworkStore, location.search]);

		const {list, meta, state} = props.NetworkStore;

		return (
			<section className="vertical center">
				<div className="page-head network-single">
					<div className="container">
						<div className="page-title">
							<h3 className="horizon middle">
								<FaNetworkWired className="mr-6"/>
								Networks
							</h3>
							<SearchInput/>
						</div>
					</div>
				</div>
				{state === 'listing' ? (
					<Loader/>
				) : (
					<div className="container page-body">
						<div className="right">
							<Filter withOffices withRegions/>
						</div>

						<div className="left ">
							<div className="card vertical top per-100">
								<div className="result-head">
									<div className="title">
										<div className="result-content">
											we found <span>{meta.count || 0}</span> results related to the networks
										</div>
									</div>
								</div>
								<AppliedFilters/>
								{/* TABLE DESIGN <table className="list-table per-100">
                  <thead>
                  <tr>
                    <td>Network Name</td>
                    <td>Network URL</td>
                    <td>Region</td>
                    <td>Office</td>
                    <td># of Clients</td>
                    <td># of Agencies</td>
                  </tr>
                  </thead>
                  <tbody> */}
								<div className="per-100 grid col-200">
									{list.map(i => (
										<NetworkCard key={"network_list_" + i.id} data={i}/>
									))}
								</div>
								{/* </tbody>
                </table> */}


							</div>
							<Pagination
								className="pagination mt-30 mb-40"
								page={(meta.offset / meta.limit) >> 0}
								count={meta.count}
								limit={meta.limit}
								range={9}
							/>
						</div>
					</div>
				)}
			</section>
		);
	})
);
