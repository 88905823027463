import {AddressFormatter} from 'components';
import {inject, observer} from 'mobx-react';
import React from 'react';

export const Overview = inject('UIStore')(
	observer(props => {
		const {item, clientCount, agencyCount} = props;

		return (
			<div className="card p-0 overview-card">
				<h2 className="sz-16 fw-500 p-20 pt-10 pb-10">Overview</h2>
				{(item.offices || []).length ? (
					<div className="section">
						<p>Offices</p>
						{item.offices.map(i => (
							<AddressFormatter
								key={'office_' + i.id}
								city={i.city}
								region={i.region}
								country={i.country}
							/>
						))}
					</div>
				) : (
					<React.Fragment/>
				)}

				<div className="section">
					<p>Sectors</p>
					<span>Network cannot have sectors</span>
				</div>

				<div className="section">
					<p># of Agencies</p>
					<div className="count">
						<span>{agencyCount || 0}</span>
					</div>
				</div>

				<div className="section">
					<p># of Unique Clients</p>
					<div className="count">
						<span>{clientCount || 0}</span>
					</div>
				</div>
			</div>
		);
	})
);
