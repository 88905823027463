import {action, computed, observable, toJS} from "mobx";

export class Account {

	client_id = 100352;
	@observable client_name = "Scout Bags";
	@observable client_type_cd = "STANDARD";
	@observable client_url = null;
	@observable address = "str1";
	@observable city = "str";
	@observable state = "str";
	@observable zip_code = "str";
	@observable country = "str";
	@observable phone_number = null;
	urls = null;
	url = false;
	@observable settings = {};
	@observable utcOffset = 0;
	@observable status_cd;
	@observable timezone_id;

	constructor(data) {

		this.client_id = data.client_id;
		this.client_name = data.client_name;
		this.client_type_cd = data.client_type_cd;
		this.client_url = data.client_url;
		this.address = data.address;
		this.city = data.city;
		this.state = data.state;
		this.zip_code = data.zip_code;
		this.country = data.country;
		this.phone_number = data.phone_number;
		this.urls = data.urls;
		this.status_cd = data.status_cd;
		this.timezone_id = data.timezone_id;

		this.authorized_url_count = data.authorized_url_count || 0;
		this.authorized_urls = Object.values(data.authorized_urls || {});
		this.timezone = data.timezone_text || 'UTC-08:00';
		this.url = data.url ? data.url : data.urls ? data.urls[0] : {};
		this.urlTimezone = this.url ? this.url.timezone_text || 'UTC-08:00' : 'UTC-08:00';
		const regex = /UTC[-+][0-9][0-9]:[0-9][0-9]/i;
		this.UTCOffset = this.timezone.match(regex) ? this.timezone.match(regex)[0].replace('UTC', '') : '+0';
		this.URLUTCOffset = this.urlTimezone.match(regex) ? this.urlTimezone.match(regex)[0].replace('UTC', '') : '+0';
	}

	@computed
	get id() {
		return this.client_id
	}

	@computed
	get name() {
		return this.client_name
	}

	@action
	changeUrl = (id) => {
		const urls = toJS(this.urls.filter(i => i.url_id === Number(id)));
		if (urls[0]) {
			this.url = toJS(urls[0]);
			this.timezone = urls[0].timezone_text || 'UTC-08:00';
			const regex = /UTC[-+][0-9][0-9]:[0-9][0-9]/i;
			this.utcOffset = this.timezone.match(regex)[0].replace('UTC', '');
			localStorage.setItem('me', JSON.stringify(this));
		}
	};

	@action
	setURL = (url) => {
		this.url = url;
		this.timezone = url.timezone_text || 'UTC-08:00';
		const regex = /UTC[-+][0-9][0-9]:[0-9][0-9]/i;
		this.utcOffset = this.timezone.match(regex)[0].replace('UTC', '');
		localStorage.setItem('me', JSON.stringify(this));
	};

}
