import {request} from 'helpers';

export class Services {

	static get = async params => {
		const getRes = await request.get('/contacts/', {...params}, true);
		//console.log("getRes",{getRes})
		return getRes;
	};

	static getDetail = async (id) => {
		const getRes = await request.get(`/contacts/${id}`, {}, true);
		//console.log("getRes",{getRes})
		return getRes;
	}
}
