import {action, computed, observable, toJS} from 'mobx';
import {SystemMessage} from 'models';

export default class {
	@observable newRelease = false;

	constructor(Stores) {
		this.stores = Stores;
		this.model = SystemMessage;
	}

	@observable _list = new observable.array();

	get list() {
		return toJS(this._list)
	}

	@computed
	set list(item) {
		return this._list.set(item);
	}

	get news() {
		return this.list.filter(i => i.isDisplayed === false)
	}

	@action
	remove(id) {
		this._list.delete(id);
	}

	@action
	add(doc) {
		const item = new this.model(doc);
		const legacy = this._list.filter(i => i.createdAt === doc.createdAt);
		if (legacy.length) {
			if (JSON.stringify(item) !== JSON.stringify(legacy[0]))
				this._list.push(item);
		} else
			this._list.push(item);
	}

	handleError({code, message}) {
		this.add({status: 400, code, message, createdAt: new Date()});
		return false;
	}

	@action
	setNewRelease(v, message) {
		console.log({message})
		this.newRelease = v;
		this.add({
			status: 100,
			code: 'NEW_VERSION',
			message,
			createdAt: new Date(),
			timeless: true
		})
	}
}

