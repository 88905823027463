import React from "react";

export const UrlItem = (props) => {
	return (
		<div className={"item pl-10 " + (props.status === 'ACTIVE' ? "" : 'disabled')} {...props}>
			<p className="sz-14 f-white">
				{props.url}
				<span>  {props.country}</span>
			</p>
			<span className="sz-12 f-light-purple">{props.id}</span>
			<div className={"status" + (props.status === 'ACTIVE' ? "active" : 'pending')}/>
		</div>);
};
