import {request} from 'helpers';

export class Services {
	static clients = async ({page, id, params}) => {
		const getRes = await request.get(`/${page}/${id}/clients/`, params, true);
		//console.log("getRes",{getRes})
		return getRes;
	};

	static agencies = async ({page, id, params}) => {
		const getRes = await request.get(`/${page}/${id}/related_agencies/`, params, true);
		//console.log("getRes",{getRes})
		return getRes;
	};
}
