import React from 'react';
import {MdClose} from 'react-icons/all';

export const AppliedFilters = props => {
	return (
		<div className="applied-filters ivy left top per-100 border-box">
			<div className="item">
				<span>Computer</span>
				<div className="icon">
					<MdClose/>
				</div>
			</div>
			<div className="item">
				<span>Software</span>
				<div className="icon">
					<MdClose/>
				</div>
			</div>
			<div className="item">
				<span>Bag</span>
				<div className="icon">
					<MdClose/>
				</div>
			</div>
			<button>Clear Filter</button>
		</div>
	);
};
